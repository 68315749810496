// extracted by mini-css-extract-plugin
export var pressCard__date = "b8_nS";
export var pressCard__description = "b8_nR";
export var pressCard__image_large = "b8_nJ";
export var pressCard__image_small = "b8_nK";
export var pressCard__info = "b8_nL";
export var pressCard__info_large = "b8_nM";
export var pressCard__info_small = "b8_nN";
export var pressCard__large = "b8_nT";
export var pressCard__link = "b8_nH";
export var pressCard__post = "b8_nG";
export var pressCard__small = "b8_nF";
export var pressCard__source = "b8_nP";
export var pressCard__title = "b8_nQ";